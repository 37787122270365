import ApiService from './ApiService'
import type {
    Partner,
    Domain,
} from '@/@types/data'

export async function apiPartnersFetch() {
    console.log('apiPartnersFetch =>')
    return ApiService.fetchData<any>({
        url: '/partner/list',
        method: 'get',
    })
}

export async function apiDomainsFetch() {
    console.log('apiDomainsFetch =>')
    return ApiService.fetchData<any>({
        url: '/domain/list',
        method: 'get',
    })
}

export async function apiIndustriesFetch() {
    console.log('apiIndustriesFetch =>')
    return ApiService.fetchData<any>({
        url: '/industry/list',
        method: 'get',
    })
}

export async function apiAutomationsFetch() {
    console.log('apiAutomationsFetch =>')
    return ApiService.fetchData<any>({
        url: '/automation/list',
        method: 'get',
        params: {
            query: {
                sortBy: undefined,
                pageSize: 2000,
                pageIndex: 0,
                filters: undefined,
                globalFilters: undefined,
                populate: ["partner", "partner.domain","etlScript" ],  // notes
            }
        }
    })
}

export async function apiScriptsFetch() {
    console.log('apiScriptsFetch =>')
    return ApiService.fetchData<any>({
        url: '/scripts/',
        method: 'get',
    })
}

export async function apiCurrenciesFetch() {
    console.log('apiCurrenciesFetch =>')
    return ApiService.fetchData<any>({
        url: '/currency/list',
        method: 'get',
    })
}

